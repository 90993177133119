<template>
  <div>
    <div
      class="bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-banner.jpg')"
    >
      <!-- header  -->
      <div class="relative z-10">
        <div class="container">
          <TopHeader />
        </div>
      </div>
      <MainBanner />
    </div>
    
    <div id="our_services" class="mt-10 mb-10">
      <div class="container">
        <p class="text-2xl font-semibold text-center text-gray-700 md:text-3xl">
          Our Specialist
        </p>
        <div class="bg-blue-1 w-12 mx-auto mt-3 h-0.5"></div>

        <div class="mt-4 text-center md:text-lg">
          <p>Custom Made</p>

          <div class="mt-4">
            <hooper :settings="hooperSettings" class="h-full">
              <slide
                v-for="(slide, indx) in slides"
                :key="indx"
                :index="indx"
                class="px-2"
              >
                <div class="relative mx-auto">
                  <img
                    :src="'/images/' + slide.image + '.jpg'"
                    alt=""
                    class="w-full"
                  />
                  <div
                    class="absolute top-0 left-0 w-full h-full bg-black bg-opacity-40"
                  >
                    <p
                      class="pt-8 pl-3 text-lg font-semibold text-left text-white"
                    >
                      {{ slide.p }}
                    </p>
                  </div>
                </div>
              </slide>
              <hooper-navigation slot="hooper-addons"></hooper-navigation>
            </hooper>
          </div>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <div
      id="why_choose_us"
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-choose.jpg')"
    >
      <div class="container">
        <p class="text-2xl font-semibold text-center text-white md:text-3xl">
          Why Choose Us
        </p>
        <div class="bg-blue-1 w-12 mx-auto mt-3 h-0.5"></div>

        <div class="mt-6 md:mt-10 md:flex md:flex-wrap">
          <div
            v-for="(item, i) in choose_us"
            :key="i"
            class="flex items-start pb-6 md:w-1/2"
          >
            <img
              :src="'/images/' + item.image + '.png'"
              :alt="item.alt"
              class="w-14 h-14"
            />
            <div class="pl-4 text-white">
              <p class="text-lg font-medium uppercase">{{ item.alt }}</p>
              <p class="pt-2 text-sm">
                {{ item.p }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- sub banner -->
    <div class="py-10">
      <div class="container">
        <div class="md:flex md:items-center xl:max-w-5xl xl:mx-auto">
          <div class="mb-4 md:w-1/2">
            <p class="pb-2 text-2xl text-gray-600 lg:text-3xl">
              Let Us Help You
            </p>
            <h1 class="text-4xl md:text-5xl lg:text-6xl text-gray-1">
              GET YOUR <span class="text-blue-1">DREAM KITCHEN</span> TODAY!
            </h1>

            <div class="mt-6">
              <p class="w-64 py-1 text-center text-white bg-red-500 rounded-md">
                <a href="https://wa.me/60173011045">GET FREE QUOTE TODAY!</a>
              </p>
            </div>
          </div>
          <div class="shadow-md md:w-1/2 lg:w-1/3 lg:mx-auto">
            <img src="/images/banner-1.jpg" alt="" class="" />
          </div>
        </div>
      </div>
    </div>

    <!-- operate -->
    <div class="py-10">
      <div class="container">
        <div class="md:flex md:flex-wrap lg:max-w-4xl lg:mx-auto">
          <div
            v-for="(item, i) in sub_info"
            :key="i"
            class="pb-5 md:px-2 md:w-1/3"
          >
            <div class="w-3/4 p-6 mx-auto shadow-lg md:w-full">
              <img
                :src="'/images/' + item.image + '.png'"
                :alt="item.alt"
                class="w-20 mx-auto"
              />
              <div class="mt-6 text-center">
                <p
                  class="text-base font-semibold md:leading-tight md:h-14 text-blue-1"
                >
                  {{ item.alt }}
                </p>
                <p
                  class="pt-4 text-sm leading-tight text-gray-600 md:h-32 lg:h-28"
                >
                  {{ item.p }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- promotion -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <div>
          <div class="pb-6">
            <p class="text-2xl font-semibold text-center text-gray-700">
              Our Promotion
            </p>
            <div class="bg-blue-1 w-12 mx-auto mt-3 h-0.5"></div>
          </div>
          <div class="md:flex md:flex-wrap lg:max-w-4xl lg:mx-auto">
            <div
              v-for="image in promotion"
              :key="image"
              class="mb-4 transition duration-300 ease-linear md:px-1 md:w-1/2"
            >
              <img :src="'/images/' + image + '.jpg'" alt="" />
              <div class="mt-5">
                <p
                  class="py-1 mx-auto text-center text-white bg-red-500 rounded-md w-60"
                >
                  <a href="https://wa.me/60173011045">Get The Special Promo!</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- about us -->
    <div id="about_us" class="py-10">
      <div class="container">
        <div class="md:flex md:flex-wrap md:items-center">
          <div class="md:w-1/2 md:pr-4">
            <p class="text-lg font-medium text-gray-600 lg:text-2xl">
              About Us
            </p>
            <h1 class="text-3xl font-semibold lg:text-4xl text-gray-1">
              Sky Walker <span class="text-blue-1">Interior</span>
            </h1>
            <div class="bg-blue-1 w-12 mt-3 h-0.5"></div>
            <p class="pt-6 text-sm leading-tight text-gray-600">
              SkyWalker Interior is a proud Malaysian local company with custom
              cabinet manufacturer Malaysia with over 10 years of experience. We
              fully provide customized cabinet solution that aims to fulfil
              different customer need. <br /><br />
              We provide a series of cabinet products which include kitchen
              cabinets, wardrobes, shoe cabinets, study cabinets, TV Consoles,
              etc. As a cabinet specialist, we are committed to providing
              high-quality cabinets and reasonable cabinet prices to our dearest
              customers. <br /><br />
              Whether you are looking for a modern, country, or transitional
              style, we will be able to provide the perfect kitchen cabinet for
              your needs.
            </p>
          </div>
          <div class="mt-4 md:w-1/2 lg:w-1/3 lg:mx-auto md:mt-0">
            <img src="/images/about-us.png" alt="" class="" />
          </div>
        </div>
      </div>
    </div>

    <!-- gallery -->
    <div class="py-10">
      <div class="container">
        <p class="text-2xl font-semibold text-center text-gray-700">
          Our Gallery
        </p>
        <div class="bg-blue-1 w-12 mx-auto mt-3 h-0.5"></div>

        <div class="flex flex-wrap mt-6">
          <div
            v-for="image in gallery"
            :key="image"
            class="w-1/2 p-1 lg:w-1/5 md:w-1/3"
          >
            <img
              :src="'/images/' + image + '.jpg'"
              alt=""
              class="transition duration-300 transform md:w-full hover:scale-110 hover:shadow-md"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- testimonials -->
    <Testimonials />

    <!-- footer -->
    <div
      id="element"
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-enquiry.jpg')"
    >
      <div class="container lg:flex lg:items-center">
        <div class="md:w-2/3 md:mx-auto lg:w-1/2 lg:order-2">
          <!-- enquiry form  -->
          <div class="p-6 rounded-md bg-blue-1 bg-opacity-60">
            <div class="text-center">
              <p class="text-3xl font-semibold text-white">Send an Enquiry</p>
              <div class="bg-white w-14 h-0.5 mx-auto my-3"></div>
              <p class="pt-3 text-sm text-gray-100">
                Fill out the form below and we'll get back to you as soon as
                possible
              </p>
            </div>
            <EnquiryForm class="pt-4" />
          </div>
        </div>
        <div class="pt-5 lg:w-1/2 lg:pr-4">
          <img
            src="/images/main-logo-white.png"
            alt=""
            class="mx-auto w-60 md:w-80"
          />
          <div class="pt-3 text-center text-white">
            <div class="pb-5">
              <h1 class="pb-2 text-lg font-medium lg:text-xl text-blue-1">
                SKYWALKER INTERIOR ENTERPRISE
              </h1>
              <h1 class="text-lg font-normal leading-tight md:text-xl">
                40, Jalan Utama 2/28, Taman Perindustrian Puchong Utama, 47150
                Puchong, Selangor
              </h1>
              <div class="pt-5">
                <p class="font-bold">Message Us on WhatsApp</p>
                <p
                  class="font-medium transition duration-300 transform hover:scale-110 hover:text-blue-1"
                >
                  <a href="https://wa.me/60173011045" class=""
                    >+6017-301 1045</a
                  >
                </p>
              </div>

              <div class="pt-4 font-medium">
                <p class="font-bold">Call Us</p>
                <p
                  class="font-medium transition duration-300 transform hover:scale-110 hover:text-blue-1"
                >
                  <a href="tel:+60173011045">+6017-301 1045</a>
                </p>
              </div>

              <div class="pt-4 font-medium">
                <p class="font-bold">Operating Hours</p>
                <p>Monday – Friday: 10AM – 5PM</p>
                <p>(Factory visit by appointment only)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="py-3 bg-gray-1">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import TopHeader from "@/components/TopHeader.vue";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";
import Testimonials from "@/components/Testimonials.vue";
import EnquiryForm from "@/components/EnquiryForm.vue";
import MainBanner from "@/components/MainBanner.vue";

export default {
  components: {
    TopHeader,
    Hooper,
    Slide,
    HooperNavigation,
    Testimonials,
    EnquiryForm,
    MainBanner,
  },
  data() {
    return {
      hooperSettings: {
        itemsToShow: 1,
        centerMode: true,
        autoPlay: true,
        infiniteScroll: true,
        hoverPause: false,
        wheelControl: false,
        playSpeed: 4000,
        breakpoints: {
          767: {
            centerMode: false,
            itemsToShow: 2,
          },
          1000: {
            itemsToShow: 4,
            centerMode: false,
          },
        },
      },
      slides: [
        { image: "service-1", p: "Kitchen Cabinet" },
        { image: "service-2", p: "Built-In Wardrobe " },
        { image: "service-3", p: "TV Cabinet" },
        { image: "service-4", p: "Shoe Cabinet" },
        { image: "service-5", p: "Bedroom Cabinet " },
        { image: "service-6", p: "Filling Cabinet" },
      ],
      choose_us: [
        {
          image: "choose-1",
          alt: "Interior Expertise",
          p: "Our Sales Team will assist you with the professional interior concept",
        },
        {
          image: "choose-2",
          alt: "Free Consultation",
          p: "We will provide a free consultation for a better solution for your living home.",
        },
        {
          image: "choose-3",
          alt: "Cost Effective",
          p: "We will ensure you satisfaction with our quality with reasonable price.",
        },
        {
          image: "choose-4",
          alt: "No Hidden Cost",
          p: "There is nothing we despise more than hiding truth from our customers.",
        },
        {
          image: "choose-5",
          alt: "Quality Assurance",
          p: "We ensure we give the best quality service for you",
        },
        {
          image: "choose-6",
          alt: "Customer Support",
          p: "We ensure to provide a good customer support for our customers.",
        },
      ],
      sub_info: [
        {
          image: "icon-1",
          alt: "Direct from Factory",
          p: "Direct Factory Price, Cheap and Affordable. To provide the best quality and cheapest in the custom-made kitchen cabinet markets.",
        },
        {
          image: "icon-2",
          alt: "Personalize Interior Design Services",
          p: "We deliver solution from scratch to ensure that the creation only for you",
        },
        {
          image: "icon-3",
          alt: "Full Customization",
          p: "Custom-built cabinets specially upon request for both home & office",
        },
      ],
      promotion: ["promo-1", "promo-2"],
      gallery: [
        "gallery-01",
        "gallery-02",
        "gallery-03",
        "gallery-04",
        "gallery-05",
        "gallery-06",
        "gallery-07",
        "gallery-08",
        "gallery-09",
        "gallery-10",
      ],
    };
  },
};
</script>

<style>
.hooper-next,
.hooper-prev {
  background-color: rgba(255, 255, 255, 0.616);
}
/* .custom-margin {
    margin-top: 60%;
  } */
/* .custom-height{
  min-height: 50px;
} */

@media (min-width: 1900px) {
  .custom-margin {
    margin-top: 290px !important;
  }
}
</style>
