<template>
  <div>
    <div class="py-52">
      <div class="container">
        <div class="md:w-2/3 lg:w-1/2">
          <div class="px-4 py-8 text-white bg-gray-1 bg-opacity-90">
            <p class="text-lg leading-tight lg:text-xl">
              Experts in High Quality and Affordable
            </p>
            <h1 class="py-2 text-2xl leading-tight lg:text-4xl md:text-3xl">
              Customize <span class="text-blue-1">Kitchen Cabinet</span> and
              Build-in <span class="text-blue-1">Wardrobe</span>
            </h1>
            <p
              class="font-medium leading-tight md:text-lg lg:text-base md:pt-4"
            >
              Quality GUARANTEED with LOWEST Price in Malaysia
            </p>

            <div v-if="!hideEnquiry" class="mt-3">
              <p
                class="w-48 py-1 text-center transition duration-300 transform bg-red-500 rounded-md hover:scale-110"
              >
                <a href="https://wa.me/60173011045">Get Your Free Quote</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.custom-bottom{
  margin-bottom: 59%;
}
</style>
