<template>
  <div>
    <div class="py-10">
      <div class="container">
        <p class="text-2xl font-semibold text-center text-gray-700">
          Our Reviews
        </p>
        <div class="bg-blue-1 w-12 mx-auto mt-3 h-0.5"></div>

        <div class="mt-6">
          <carousel
            :autoplay="true"
            :loop="true"
            :autoplaySpeed="2500"
            :autoplayTimeout="6000"
            :responsive="{
              0: { items: 1, nav: false, dots: true },
              767: { items: 2, nav: false, dots: true },
              1000: { items: 3, nav: false, dots: true },
            }"
          >
            <div v-for="(item, i) in testimonials" :key="i" class="px-1">
              <div class="p-5 rounded-md bg-blue-50">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  class="w-10 h-10 fill-current text-blue-1"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z"
                  />
                </svg>
                <div class="pt-3">
                  <p class="text-sm text-gray-600 h-52">
                    {{ item.p }}
                  </p>
                </div>
                <div class="pt-4">
                  <p class="text-lg font-semibold">{{ item.client }}</p>
                  <div class="text-yellow-400">
                    <i class="fa-sharp fa-solid fa-star"></i>
                    <i class="fa-sharp fa-solid fa-star"></i>
                    <i class="fa-sharp fa-solid fa-star"></i>
                    <i class="fa-sharp fa-solid fa-star"></i>
                    <i class="fa-sharp fa-solid fa-star"></i>
                  </div>
                </div>
              </div>
            </div>
          </carousel>
        </div>
        <div class="hidden">
          <img src="/images/bg-testimonial.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
  components: {
    carousel,
  },
  data() {
    return {
      testimonials: [
        {
          p: "I am extremely pleased with their work. They were good and professional. They took careful when dismantling the full height wardrobe so as not to damage to my false ceiling. cleared and room cleaned up very tidily.",
          client: "Anson Lee",
        },
        {
          p: "Had a great experience. The standards were met in fully professional manner. I am satisfied with quality work and value for money. Keep up the good work",
          client: "Florence Wong",
        },
        {
          p: "We had such a great experience with this company from the beginning to the end. Our kitchen looks great and we are very thankful to have had a great team being respectful to our home and working over and beyond to make sure we were overall happy. Many Thanks.",
          client: "Lydia Wong",
        },
        {
          p: "I am impressed with their professionalism and great craftsmanship. They were patience and accommodating to my needs. They also provided timely suggestions which aided in my decision making. Hence, the end-products were astonishing and I received compliments on its design and quality.",
          client: "En. Asyraf",
        },
        {
          p: "My wife and I were looking to make a wardrobe. We did not regret choosing SkyWalker. The process from start to end was seamless and the whole thing was completed within a reasonable period. Kenny was very accommodating. Thank you, bro!",
          client: "Kumar",
        },
        {
          p: "We were impressed by the workmanship and design. Kenny was patient and attentive to our needs. Excellent service. It was such a pleasure to hear his story. Delivery team were polite. They came on time and took great care of our items. Highly recommended. ",
          client: "Yusof",
        },
      ],
    };
  },
};
</script>
